import {useEffect, useState} from "react";
import NextLink from "next/link"
import {useRouter} from "next/router";

import { useTheme } from "@mui/material/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { DrupalNode } from "next-drupal";
import { convertPaths, disabledPaths, removedPaths, renamedPaths } from "@helper/breadcrumbs";
import { removeHtmlTags } from "@helper/parseHTML";

export interface BreadcrumbsProps {
  node: DrupalNode;
  override?: string;
}

export function BreadcrumbsComponent({ node, override }: BreadcrumbsProps) {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const isHeroBgBlue: boolean = node?.field_paragraph?.at(0)?.field_hero_style === 'blue' || node?.field_paragraphs?.at(0)?.field_hero_style === 'blue';

  const [items, setItems] = useState([]);

  /* 
   *  TODO: spostare logica generazione Breadcrumbs qui dentro, passare solamente node e generarli.
   *  rimuovere items e noTextTransform
  */
 
  useEffect(() => {
    if(override) {
      setItems(override.split('/').filter((item) => item !== ''));
    } else if (router.asPath) {
      setItems(router.asPath.split('/').filter((item) => item !== ''))
    }
  }, [router.asPath, override])

  const cleanPath = (path) => {
    return path
      .trim()
      .split('?')[0]
      .split('#')[0]
      .split(/[-_\/]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('.html', '');
  };

  if (node.path?.alias === '/front') return null;

  return (
    <>
    { !isMobile && items && (
      <Container
        maxWidth={false}
        sx={
          {
            position: 'absolute',
            zIndex: 10,
            marginTop: {xs:16, lg: 34},
          }
        }
      >
        <Stack spacing={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            maxItems={3}
            expandText="expand"
            color={isHeroBgBlue ? "#fff" : "inherit"}
          >
            <NextLink href={"/"} legacyBehavior>
              <Link underline="hover" color={"primary"} sx={{ display: 'flex', alignItems: 'center' }}>
                <HomeOutlinedIcon color="primary" fontSize="small"/>
              </Link>
            </NextLink>
              

            {items.map((segment, idx) => {
              if (!segment) return null;

              const nodeTitle = node.title || node.name
              let title = cleanPath(segment);
              title = renamedPaths[title.toLowerCase()] || title;
              const isLast: boolean = idx === items.length -1;
              const pathWithConversion:string = items.slice(0, idx + 1).map(s => convertPaths[s] || s).join('/');
              const href = `/${pathWithConversion}/`;

              const disabled:boolean = disabledPaths?.includes(href);
              const excluded:boolean = removedPaths?.includes(href);

              if (excluded) return null;

              return (
                <Link
                  key={href}
                  component={(disabled || isLast) ? 'span' : NextLink}
                  href={href}
                  title={title}                  
                  underline={(disabled || isLast) ? 'none' : 'hover'}
                  color={(disabled || isLast) ? "inherit" : "primary"}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pointerEvents: (disabled || isLast) ? 'none' : 'initial',
                  }}
                >
                  
                  {(isLast && nodeTitle && !override) ?  removeHtmlTags(nodeTitle) : removeHtmlTags(title)}
                </Link>
              )
            })}

          </Breadcrumbs>
        </Stack>
      </Container>
    )}
  </>
  )
}

export default  BreadcrumbsComponent